<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Boutiques</h2>
      </div>

      <template v-if="isFetching">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="form-control mobile-wid-100" @change="changePage(1)" v-model="perPage">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="text" class="form-control quickfilter-input mobile-wid-100" placeholder="Filtrer par nom, code" @change="changePage(1)" v-model="textFilter">
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      Filtré par utilisateur:
                      <span class="bold" v-if="user">Oui</span>
                      <span class="bold" v-else>Non</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-inline">
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <a class="btn btn-sm btn-link" @click.prevent="reset" >
                        <span class="fa-stack">
                          <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                          <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <div v-if="stores.length === 0" class="card-body text-center">
              <h4 class="header-title">Vous n'avez aucune boutique</h4>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="stores"
              :show-actions="false"
              no-top-border
              hover
            ></collection-table>
          </div>
        </div>

        <div v-if="paginatedData.pageCount > 1" class="drawer-footer d-flex justify-content-center align-items-center">
          <pagination :current-page="page" :pages="paginatedData.pageCount" :range="2" @change="changePage" />
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import formats from '@/mixins/formats'
import LinkTo from '@/components/common/LinkTo'
import SwitchInput from '@/components/common/SwitchInput'
import formatsDate from '@/mixins/formatsDate'
import { getStoreRegistrationStatus } from '@/utils/status'

export default {
  components: {
    Pagination
  },
  mixins: [formats, formatsDate],
  watch: {
    $route () {
      this.fetchStores(this.page)
    }
  },
  computed: {
    isFetching () {
      return this.$store.state.stores.isFetching
    },
    stores () {
      return this.paginatedData.data
    },
    paginatedData () {
      return this.$store.state.stores.paginatedData
    },
    user () {
      return this.$route.query.user
    },
    cols () {
      return [
        {
          header: 'Nom',
          property: 'name',
          component: LinkTo,
          props: (item) => {
            return {
              haveLink: true,
              to: { name: 'stores.details', params: { id: item.id } },
              linkText: item.name,
              useCallBackLink: true,
              callBackLink: (to) => {
                this.$router.push(to)
              }
            }
          }
        },
        {
          header: 'Code',
          property: 'code'
        },
        {
          header: "Statut d'enregistrement",
          property: 'registrationStatus',
          method: (item) => {
            const skippedLabel = item.hasSkippedOnboarding ? '(Skipped)' : ''
            return `${getStoreRegistrationStatus(item)} ${skippedLabel}`
          }
        },
        {
          header: 'Administrateur(s)',
          property: 'id',
          component: LinkTo,
          props: (item) => {
            return {
              haveLink: true,
              to: { name: 'users.index', query: { store: item.id } },
              linkText: 'Afficher',
              useCallBackLink: true,
              callBackLink: (to) => {
                this.$store.commit('users/SET_PAGE', 1)
                this.$router.push(to)
              }
            }
          }
        },
        {
          header: 'Dernière mise à jour',
          property: 'updatedAt',
          method: (item) => {
            if (!item || !item.updatedAt) {
              return
            }

            return this.$longDate(item.updatedAt)
          }
        },
        {
          header: 'Active',
          property: 'isSuspended',
          component: SwitchInput,
          props: (item) => {
            return {
              checked: !item.isSuspended,
              size: 'lg',
              callBack: async (isActive) => {
                try {
                  const reason = await this.$modal.open(
                    () => import('@/modals/SuspendModal.vue'),
                    { isSuspended: !isActive, storeName: item.name, storeId: item.id }
                  )

                  // edit store isSuspended
                  await this.$store.dispatch(
                    'stores/editStatus',
                    { storeId: item.id, isSuspended: !isActive, reason }
                  )
                  const successMessage = !isActive
                    ? `Boutique ${item.name} suspendue avec succès.`
                    : `Boutique ${item.name} activée avec succès.`
                  this.$toasted.success(successMessage)
                } catch (e) {
                  if (e === 'ACTION_CANCELLED') {
                    return
                  }

                  this.$toasted.error(e?.response?.data)
                }
              }
            }
          }
        }
      ]
    },
    textFilter: {
      get () {
        return this.$store.state.stores.textFilter
      },
      set (value) {
        this.$store.commit('stores/SET_TEXT_FILTER', value)
      }
    },
    perPage: {
      get () {
        return this.$store.state.stores.perPage
      },
      set (value) {
        this.$store.commit('stores/SET_PER_PAGE', value)
      }
    },
    page () {
      return this.$store.state.stores.page
    }
  },
  methods: {
    reset () {
      this.$toasted.info('Les filtres sont réinitialisés')
      this.textFilter = ''
      if (this.user) {
        this.$router.push({ name: 'stores.index' })
      }
      this.changePage(1)
    },
    async changePage (page) {
      await this.fetchStores(page)
    },
    async fetchStores (page) {
      if (this.user) {
        await this.$store.dispatch('stores/fetchByUser', { page, user: this.user })
      } else {
        await this.$store.dispatch('stores/fetch', { page })
      }
    }
  },
  async created () {
    await this.fetchStores()
  }
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
  margin-left: 6px;
}
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>
