<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Utilisateurs</h2>
      </div>

      <template v-if="isFetching">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="form-control mobile-wid-100" @change="changePage(1)" v-model="perPage">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="text" class="form-control quickfilter-input mobile-wid-100" placeholder="Filtrer par nom, courriel,..." @change="changePage(1)" v-model="textFilter">
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      Filtré par boutique:
                      <span class="bold" v-if="store">Oui</span>
                      <span class="bold" v-else>Non</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ml-2 hidden-md"></div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <a class="btn btn-sm btn-link" @click.prevent="reset" >
                        <span class="fa-stack">
                          <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                          <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <div v-if="users.length === 0" class="card-body text-center">
              <h4 class="header-title">Vous n'avez aucun utilisateur</h4>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="users"
              :show-actions="false"
              no-top-border
              hover
            ></collection-table>
          </div>
        </div>

        <div v-if="paginatedData.pageCount > 1" class="drawer-footer d-flex justify-content-center align-items-center">
          <pagination :current-page="page" :pages="paginatedData.pageCount" :range="2" @change="changePage" />
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import formats from '@/mixins/formats'
import useValidator from '@/mixins/useValidator'
import userData from '@/mixins/user-data'
import LinkTo from '@/components/common/LinkTo'
import InlineSelect from '@/components/common/InlineSelect'

export default {
  components: {
    Pagination
  },
  mixins: [formats, useValidator, userData],
  watch: {
    $route () {
      this.fetchUsers(this.page)
    }
  },
  computed: {
    isFetching () {
      return this.$store.state.users.isFetching
    },
    users () {
      return this.paginatedData.data
    },
    paginatedData () {
      return this.$store.state.users.paginatedData
    },
    store () {
      return this.$route.query.store
    },
    userRoleList () {
      return [
        {
          value: 'admin',
          label: 'Admin'
        },
        {
          value: 'user',
          label: 'Utilisateur'
        }
      ]
    },
    validationUserRoleRules () {
      return {
        userId: 'required',
        roleName: 'required|in:admin,user'
      }
    },
    cols () {
      return [
        {
          header: 'Nom / Prénom',
          property: 'firstName',
          method: (item) => {
            return `${item.lastName} ${item.firstName}`
          }
        },
        {
          header: 'Courriel',
          property: 'email'
        },
        {
          header: 'Téléphone',
          property: 'phone',
          method: (item) => {
            return item.phone ? this.formattedPhoneNumber(item.phone) : ''
          }
        },
        {
          header: 'Type de compte',
          property: 'id',
          component: LinkTo,
          props: (item) => {
            let text = 'Client'
            let haveLink = false
            let to = {}
            let linkText = ''

            if (item.stores && item.stores.length > 0) {
              text = `${text} / Vendeur`
              haveLink = true
              to = { name: 'stores.index', query: { user: item.id } }
              linkText = 'voir le(s) boutique(s)'
            }

            return {
              text,
              haveLink,
              to,
              linkText,
              useCallBackLink: true,
              callBackLink: (to) => {
                this.$store.commit('stores/SET_PAGE', 1)
                this.$router.push(to)
              }
            }
          }
        },
        {
          header: 'Rôle',
          property: 'role',
          component: InlineSelect,
          props: (item) => {
            const disable = this.currentUser && item.role && this.currentUser.id === item.role.userId

            return {
              item,
              callBack: this.updateRole,
              displayValue: item.role ? this.getRoleValue(item.role.roleName) : 'Utilisateur',
              list: this.userRoleList,
              defaultValue: item.role ? item.role.roleName : 'user',
              disable
            }
          }
        }
      ]
    },
    textFilter: {
      get () {
        return this.$store.state.users.textFilter
      },
      set (value) {
        this.$store.commit('users/SET_TEXT_FILTER', value)
      }
    },
    perPage: {
      get () {
        return this.$store.state.users.perPage
      },
      set (value) {
        this.$store.commit('users/SET_PER_PAGE', value)
      }
    },
    page () {
      return this.$store.state.users.page
    }
  },
  methods: {
    getRoleValue (roleName) {
      if (roleName === 'admin') {
        return this.capitalize(roleName)
      }

      return 'Utilisateur'
    },
    async updateRole (value, item) {
      const form = {
        userId: item.id,
        roleName: value
      }

      try {
        if (!this.validate(form, this.validationUserRoleRules)) {
          this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
          return
        }

        if (item.role && item.role.id) {
          await this.$store.dispatch('userRoles/update', { ...form, id: item.role.id })
        } else {
          await this.$store.dispatch('userRoles/create', form)
        }

        this.$toasted.success(`${item.firstName} ${item.lastName} à maintenant le rôle ${value === 'admin' ? 'admin' : 'utilisateur'}`)

        await this.changePage(this.page)
      } catch (e) {
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      }
    },
    reset () {
      this.$toasted.info('Les filtres sont réinitialisés')
      this.textFilter = ''
      if (this.store) {
        this.$router.push({ name: 'users.index' })
      }
      this.changePage(1)
    },
    async changePage (page) {
      await this.fetchUsers(page)
    },
    async fetchUsers (page) {
      if (this.store) {
        await this.$store.dispatch('users/fetchByStore', { page, store: this.store })
      } else {
        await this.$store.dispatch('users/fetch', { page })
      }
    }
  },
  async created () {
    await this.fetchUsers()
  }
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
  margin-left: 6px;
}
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>
